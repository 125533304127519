.Container {
  padding: 40px 72px;
}

.Card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  border-radius: 12px;
  border: 1px solid #d8d8d8;
}

.CardTitle {
  color: #363636;

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.4px;
}

.Contents {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-wrap: wrap;
}

.Header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

.IconButton {
  height: 32px;
  display: flex;
  align-items: center;
}

.Select {
  width: 43px;
  height: 28px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  background-color: transparent;
}

.Form {
  display: flex;
  flex-wrap: wrap;
}

.Form:last-child {
  padding: var(--Radius-S, 8px) 0px;
}

.FormTitle {
  width: 240px;
  color: var(--color-text-level1, #363636);
  font-size: var(--font-size-Regular, 16px);
  font-style: normal;
  font-weight: 500;
  padding: var(--Radius-M, 12px) var(--Space-Small, 8px) var(--Radius-M, 12px)
    0px;
}

.FormContent {
  padding: var(--Radius-S, 8px) 0px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  align-items: flex-start;
}
