.Wrapper {
  display: flex;
  width: 640px;
  min-width: 560px;
  max-width: 800px;
  flex-direction: column;
  align-items: center;
}

.Header {
  display: flex;
  padding: 0px var(--Space-3XLarge, 40px);
  align-items: center;
  gap: var(--Space-Small, 8px);
  align-self: stretch;
  color: var(--color-bg-icon-level1, #363636);
  /* Heading/Heading Large-M */
  font-size: var(--font-size-XLarge, 20px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--font-multi-line-height-XLarge, 30px); /* 150% */
  letter-spacing: var(--font-letter-spacing-XLarge, -0.4px);
}

.Contents {
  display: flex;
  flex-direction: column;
  padding: 16px 24px 0px 40px;
  align-items: flex-start;
  align-self: stretch;
  overflow-y: scroll;
  min-height: 345px;
  max-height: 345px;
  flex: 1;
}

.PageNavigation {
  display: flex;
  width: 560px;
  padding: 24px 256px 0px 256px;
  align-items: flex-start;
  gap: 10px;
  border-top: 1px solid #eee;
}

.NavigationActive {
  color: #363636;
}

.NavigationInActive {
  color: #c3c3c3;
}

.Footer {
  display: flex;
  padding: 4px 40px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
  color: var(--color-text-level1, #363636);
}

.FooterButton {
  display: flex;
  align-items: center;
  gap: 8px;
}

.FooterCheckBox {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #c5c5c5;
}

.FileListContainer {
  width: 100%;
  max-width: 100%;
  padding: 0 40px;
}

.FileList {
  justify-content: flex-start;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  gap: 16px;
  padding: 24px 0px;
  white-space: nowrap;
}

.FileRow {
  display: flex;

  gap: 16px;
}

.File {
  display: flex;
  gap: 8px;
  align-items: center;
}
