.Input {
  min-height: 34px;
  padding: 4px;
  flex: 1;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  width: 100%;
}
.Select {
  display: flex;
  height: 40px;
  padding: 0px var(--Space-Small, 8px) 0px var(--Space-Medium, 12px);
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--color-border-field, #d8d8d8);
}

.RadioGroup {
  display: flex;
  gap: 40px;
  align-items: center;
}

.Radio {
  display: flex;
  align-items: center;
  gap: 8px;
}

.SelectGroup {
  display: flex;
  gap: 24px;
  align-items: center;
}
.SpecificDate {
  display: flex;
  gap: 8px;
  align-items: center;
}

.DatePicker {
  display: flex;
  height: 40px;
  padding: 0px var(--Space-Large, 16px) 0px var(--Space-Small, 8px);
  align-items: center;
  border-radius: var(--Radius-S, 8px);
  border: 1px solid var(--color-border-field, #d8d8d8);
  gap: 4px;
}

.PeriodPreview {
  color: #888;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.65px;
  padding: 2px 10px;
  border-radius: 4px;
  background: #f8f9fa;
}

.FileList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.File {
  display: flex;
  gap: 8px;
}
